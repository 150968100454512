import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Application } from "../utility/application.enum";
import { ConfiguratorService } from "../utility/configurator.service";
import {VerificationService} from "../../auth/verification/verification.service";

@Component({
  selector: 'end-customer-portal-configurator-step6',
  templateUrl: './step6.component.html',
  styleUrls: ['./step6.component.scss']
})
export class Step6Component implements OnChanges {
  @Input() visible!: boolean;
  Application = Application;
  peakShavingModel = false;
  backupPowerModel = false;
  selfConsumptionModel = false;
  // somethingElseModel = false;
  isDesktop = false;
  isMobile = false;
  isTablet = false;
  videoSrcFwMobile: string = "assets/videos/lead_gen_start_mp_01.mp4"
  videoSrcFwTablet: string = "assets/videos/tablet/lead_gen_application_mt_01.mp4"


  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['visible'].currentValue == true) {
      this.peakShavingModel = false;
      this.backupPowerModel = false;
      this.selfConsumptionModel = false;
      const video = document.getElementById('video6');
      setTimeout(() => { (video as any).play() }, 300);
    }
  }
  constructor(public configuratorService: ConfiguratorService,
              private verificationService: VerificationService) {

    this.isMobile = this.verificationService.detectMob();
    this.isTablet = this.verificationService.detectTab();
    this.isDesktop = !this.isMobile && !this.isTablet;
  }

  updateApplicationList(app: Application) {
    this.configuratorService.configurator.step6UserInteracted = true;
    switch (app) {
      case Application.peakShaving: if (this.peakShavingModel) {
        this.configuratorService.configurator.application.push(Application.peakShaving);
      } else {
        let idx = this.configuratorService.configurator.application.findIndex(itm => itm == Application.peakShaving);
        if (idx > -1) {
          this.configuratorService.configurator.application.splice(idx, 1);
        }
      }
        break;
      case Application.selfConsumption: if (this.selfConsumptionModel) {
        this.configuratorService.configurator.application.push(Application.selfConsumption);
      } else {
        let idx = this.configuratorService.configurator.application.findIndex(itm => itm == Application.selfConsumption);
        if (idx > -1) {
          this.configuratorService.configurator.application.splice(idx, 1);
        }
      }
        break;
      case Application.backupPower: if (this.backupPowerModel) {
        this.configuratorService.configurator.application.push(Application.backupPower);
      } else {
        let idx = this.configuratorService.configurator.application.findIndex(itm => itm == Application.backupPower);
        if (idx > -1) {
          this.configuratorService.configurator.application.splice(idx, 1);
        }
      }
        break;
      // case Application.somethingElse: if (this.somethingElseModel) {
      //   this.configurator.configurator.application.push(Application.somethingElse);
      // } else {
      //   let idx = this.configurator.configurator.application.findIndex(itm => itm == Application.somethingElse);
      //   if (idx > -1) {
      //     this.configurator.configurator.application.splice(idx, 1);
      //   }
      // }
      // break;
    }
  }
}
