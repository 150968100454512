import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataShareService {

    sharedObject: any = {};
    data: any = new BehaviorSubject(null);
    openCalls = 0;
    spinnerText = '';
    spinnerVisible: boolean = true;
    selectedCostInCt: number = 20;
    navExpanded = new BehaviorSubject(false);
    callCountForLoader = new BehaviorSubject(0);
    handleCallCountForLoader = this.callCountForLoader.asObservable();
    sendSimulationDataSubject = new BehaviorSubject({});
    sendSimulationDataObservable = this.sendSimulationDataSubject.asObservable();
    localeTracker = new BehaviorSubject<string>('');
    handleLocaleTracker = this.localeTracker.asObservable();
    scrollToNav: Subject<void> = new Subject();
    scrollToNavCalled = this.scrollToNav.asObservable();

    public locale: BehaviorSubject<string> = new BehaviorSubject<string>('');
    constructor() {
        this.data.next(this.sharedObject);
        // this.navExpanded.next(false);
    }

    public setData(key: string, value: any) {
        this.sharedObject[key] = value;
        this.data.next(this.sharedObject);
    }

    setLocale(value: string) {
        this.locale.next(value)
    }
}
