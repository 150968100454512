<div class="footer-container">
  <div class="container p-2">
    <div class="footer-top">
      <div class="footer-wrapper d-flex justify-content-between gap-5">
        <div class="logo-item p-0 m-0 mt-4">
          <div class="logo m-0 p-0">
            <img src="assets/tesvolt-logo-footer-2022.svg" color="gray" />
          </div>
          <div class="address">
            <h5 class="address-text">
              TESVOLT AG<br>Am Heideberg 31<br>06886 Lutherstadt Wittenberg<br>Germany
            </h5>
          </div>

        </div>
        <div class="link-area">
          <div class="important-link-wrapper d-flex justify-content-between mt-1">
            <div class="important-link d-flex flex-column mt-4">
              <a href="">{{"footer.linkTitle1" | translate}}</a>
              <a target="_blank"
                href="https://www.tesvolt.com/en/products/e-series/ts-hv-30-80-e.html">{{"footer.linkTitle2" |
                translate}}</a>
              <a target="_blank"
                href="https://www.tesvolt.com/en/products/e-series/products/e-serie/tps-e.html">{{"footer.linkTitle3" |
                translate}}</a>
              <a target="_blank" href="https://www.tesvolt.com/en/products/technology.html">{{"footer.linkTitle4" |
                translate}}</a>
              <a target="_blank" href="https://www.tesvolt.com/en/applications/peak-shaving.html">{{"footer.linkTitle5"
                | translate}}</a>
              <a target="_blank" href="https://www.tesvolt.com/en/applications/time-of-use.html">{{"footer.linkTitle6" |
                translate}}</a>
              <a target="_blank"
                href="https://www.tesvolt.com/en/applications/forecast-based-charging.html">{{"footer.linkTitle7" |
                translate}}</a>
              <a target="_blank"
                href="https://www.tesvolt.com/en/applications/semi-off-grid-operation.html">{{"footer.linkTitle8" |
                translate}}</a>
              <a target="_blank" href="https://www.tesvolt.com/en/partner/service/academy.html">{{"footer.linkTitle9" |
                translate}}</a>
              <a target="_blank" href="https://www.tesvolt.com/en/service/configurators.html">{{"footer.linkTitle10" |
                translate}}</a>
              <a target="_blank" href="https://www.tesvolt.com/en/company.html">{{"footer.linkTitle11" | translate}}</a>
              <a target="_blank" href="https://www.tesvolt.com/en/company/awards.html">{{"footer.linkTitle12" |
                translate}}</a>

            </div>
            <div class="important-link d-flex flex-column mt-4">
              <a href="https://www.tesvolt.com/en/products.html">{{"footer.linkTitle13" | translate}}</a>
              <a href="https://www.tesvolt.com/en/products/e-serie/ts-i-hv-80-e.html">{{"footer.linkTitle14" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/products/energymanagementsystem-1.html">{{"footer.linkTitle15" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/products/safety.html">{{"footer.linkTitle16" | translate}}</a>
              <a href="https://www.tesvolt.com/en/applications/multi-use.html">{{"footer.linkTitle17" | translate}}</a>
              <a href="https://www.tesvolt.com/en/applications/off-grid.html">{{"footer.linkTitle18" | translate}}</a>
              <a href="https://www.tesvolt.com/en/applications/zero-feed-in.html">{{"footer.linkTitle19" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/e-mobility/charging-station-control.html">{{"footer.linkTitle20" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/partner/planungstools.html">{{"footer.linkTitle21" | translate}}</a>
              <a href="https://www.tesvolt.com/en/service/storagepromotion.html">{{"footer.linkTitle22" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/company/newsroom.html">{{"footer.linkTitle23" | translate}}</a>
              <a href="https://www.tesvolt.com/en/company/career.html">{{"footer.linkTitle24" | translate}}</a>
            </div>
            <div class="important-link d-flex flex-column mt-4 ">
              <a href="https://www.tesvolt.com/en/products/a-series/ts-48-v.html">{{"footer.linkTitle25" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/products/e-serie/ts-i-hv-100-e.html">{{"footer.linkTitle26" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/products/a-series.html">{{"footer.linkTitle27" | translate}}</a>
              <a href="https://www.tesvolt.com/en/applications.html">{{"footer.linkTitle28" | translate}}</a>
              <a href="https://www.tesvolt.com/en/applications/back-up-power.html">{{"footer.linkTitle29" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/applications/ancillary-services.html">{{"footer.linkTitle30" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/applications/micro-grid.html">{{"footer.linkTitle31" | translate}}</a>
              <a href="https://www.tesvolt.com/en/partner.html">{{"footer.linkTitle32" | translate}}</a>
              <a href="https://www.tesvolt.com/en/partnerservice-app.html">{{"footer.linkTitle33" | translate}}</a>
              <a href="https://www.tesvolt.com/en/service/downloads.html">{{"footer.linkTitle34" | translate}}</a>
              <a href="https://www.tesvolt.com/en/company/contact.html">{{"footer.linkTitle35" | translate}}</a>
              <a href="https://www.tesvolt.com/en/company/quality.html">{{"footer.linkTitle36" | translate}}</a>
            </div>
            <div class="important-link d-flex flex-column mt-4">
              <a href="https://www.tesvolt.com/en/products/a-series/ts-i-hv-80.html">{{"footer.linkTitle37" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/products/e-series/tps-hv-80-e.html">{{"footer.linkTitle38" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/products/e-series.html">{{"footer.linkTitle39" | translate}}</a>
              <a href="https://www.tesvolt.com/en/applications/self-consumption-optimisation.html">{{"footer.linkTitle40"
                | translate}}</a>
              <a href="https://www.tesvolt.com/en/applications/pv-diesel-hybrid-optimisation.html">{{"footer.linkTitle41"
                | translate}}</a>
              <a href="https://www.tesvolt.com/en/applications/power-quality.html">{{"footer.linkTitle42" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/applications/direct-marketer-interface.html">{{"footer.linkTitle43" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/partner/become-a-partner.html">{{"footer.linkTitle44" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/service.html">{{"footer.linkTitle45" | translate}}</a>
              <a href="https://www.tesvolt.com/en/service/faq.html">{{"footer.linkTitle46" | translate}}</a>
              <a href="https://www.tesvolt.com/en/company/tradefairs-events.html">{{"footer.linkTitle47" |
                translate}}</a>
              <a href="https://www.tesvolt.com/en/projects/">{{"footer.linkTitle48" | translate}}</a>

            </div>
          </div>
          <div class="legal-info">
            <a href="https://www.tesvolt.com/en/legal-notice.html" target="_blank">{{ "footer.legalLink1" |
              translate}}</a>
            <span class="pipe">|</span>
            <a href="https://www.tesvolt.com/en/gtc.html" target="_blank">{{ "footer.legalLink2" | translate}}</a>
            <span class="pipe">|</span>
            <a href="https://www.tesvolt.com/en/privacy.html" target="_blank">{{ "footer.legalLink3" | translate}}</a>
            <span class="pipe">|</span>
            <br>
            <a href="#" onclick="CCM.openWidget(); return false;">{{ "footer.legalLink4" | translate}}</a>

          </div>
        </div>
      </div>
    </div>
    <div class="footer-middle"></div>
    <div class="footer-bottom">
      <div class="d-flex flex-column">
        <div class="text-center">
          <h5>Social Media & Contact</h5>
          <div class="d-flex flex-row justify-content-center gap-2">
            <div class="social-icon-wrapper">

              <a href="https://www.facebook.com/tesvolt" target="_blank">
                <svg class="social-icon facebook" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.93 67.93">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: none;
                      }

                      .cls-1,
                      .cls-2 {
                        stroke-width: 0px;
                      }

                      .cls-2 {
                        fill: #303030;
                      }
                    </style>
                  </defs>
                  <g id="facebook">
                    <path class="cls-2"
                      d="M29.41,55.05c0,.1,0,.21-.01.31h7.94c0-.15,0-.31,0-.46,0-6.25,0-12.5,0-18.75v-.3h.27c1.96,0,3.92,0,5.88,0,.4,0,.39,0,.44-.4.13-1.05.26-2.11.39-3.16.16-1.26.33-2.52.49-3.78.04-.29.03-.29-.27-.29-2.31,0-4.62,0-6.93,0h-.27c0-.07-.01-.12-.01-.17,0-1.6,0-3.21,0-4.81,0-.44.05-.88.14-1.31.21-1.07.85-1.77,1.92-2.01.56-.12,1.13-.18,1.7-.2,1.25-.03,2.51-.01,3.76,0,.2,0,.26-.06.26-.26,0-1.15,0-2.31,0-3.46,0-.98,0-1.96,0-2.94,0-.17-.06-.23-.23-.24-.31-.02-.61-.07-.92-.08-1.65-.06-3.3-.13-4.95-.16-1.44-.02-2.84.24-4.18.79-2,.83-3.47,2.22-4.41,4.17-.69,1.44-1,2.97-1.02,4.55-.03,1.97-.01,3.93-.02,5.9,0,.07,0,.15,0,.23h-.28c-2.02,0-4.03,0-6.05,0-.21,0-.28.05-.27.27,0,2.37,0,4.73,0,7.1,0,.27,0,.27.28.27,2.02,0,4.03,0,6.05,0h.27v.24c0,6.32,0,12.64,0,18.96Z" />
                    <circle class="cls-1" cx="33.97" cy="33.97" r="33.97" />
                  </g>
                </svg>
              </a>
            </div>
            <div class="social-icon-wrapper">
              <a href="https://www.linkedin.com/company/tesvolt/" target="_blank">
                <svg class="social-icon linkedin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.93 67.93">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: none;
                      }

                      .cls-1,
                      .cls-2 {
                        stroke-width: 0px;
                      }

                      .cls-2 {
                        fill: #303030;
                      }
                    </style>
                  </defs>
                  <g id="linkedin">
                    <rect class="cls-2" x="16.23" y="27.82" width="7.6" height="24.46" />
                    <path class="cls-2"
                      d="M20.03,24.47c2.43,0,4.41-1.98,4.41-4.41s-1.98-4.41-4.41-4.41-4.41,1.98-4.41,4.41,1.97,4.41,4.41,4.41Z" />
                    <path class="cls-2"
                      d="M36.2,40.18c0-3.19.61-6.28,4.56-6.28s3.95,3.65,3.95,6.49v11.9h7.6v-13.42c0-6.59-1.42-11.65-9.12-11.65-3.7,0-6.18,2.03-7.19,3.95h-.1v-3.34h-7.29v24.46h7.6v-12.1Z" />
                    <circle class="cls-1" cx="33.97" cy="33.97" r="33.97" />
                  </g>
                </svg>
              </a>
            </div>
            <div class="social-icon-wrapper">
              <a href="https://www.youtube.com/channel/UC2WBPUVh9PMnOzbOraew88w" target="_blank">

                <svg class="social-icon youtube" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.93 67.93">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: none;
                      }

                      .cls-1,
                      .cls-2 {
                        stroke-width: 0px;
                      }

                      .cls-2 {
                        fill: #303030;
                      }
                    </style>
                  </defs>
                  <g id="youtube">
                    <circle class="cls-1" cx="33.97" cy="33.97" r="33.97" />
                    <path class="cls-2"
                      d="M48.49,48.36c2.89-.27,3.62-.7,4.38-1.39,1.97-1.79,2.41-6.63,2.48-11.45.01-1.04.01-2.08,0-3.11-.07-4.82-.51-9.66-2.48-11.45-.76-.69-1.49-1.12-4.38-1.39-3.62-.34-8.53-.38-12.23-.39-1.53,0-3.06,0-4.59,0-3.71,0-8.61.05-12.23.39-2.89.27-3.62.7-4.38,1.39-2.01,1.82-2.43,6.8-2.48,11.7,0,.91,0,1.83,0,2.74.06,4.86.49,9.77,2.48,11.57.76.69,1.49,1.12,4.38,1.39,3.62.34,8.53.38,12.23.39,1.53,0,3.06,0,4.59,0,3.71,0,8.61-.05,12.23-.39ZM29.77,40.51v-12.7l10.8,6.15-10.8,6.54Z" />
                  </g>
                </svg>
              </a>
            </div>
            <div>
            </div>
          </div>
          <div class="copyright-section">
            <p>Made with ❤ by Tesvolt. v1.3.2</p>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>