import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';
import { DataShareService } from "./data-share.service";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
    constructor(private dataShareService: DataShareService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        return next.handle(req).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse || event instanceof HttpErrorResponse) {
                this.dataShareService.callCountForLoader.next(this.dataShareService.openCalls--)
            } else {
                this.dataShareService.callCountForLoader.next(this.dataShareService.openCalls++)
            }
            return event;
        }));
    }
}
