import { registerLocaleData } from "@angular/common";
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";
import { filter } from "rxjs";
import { DataShareService } from "../services/data-share.service";
import { StorageService } from "../services/storage.service";
import { UserService } from "../services/user.service";
import { VerificationService } from "../auth/verification/verification.service";
@Component({
  selector: 'end-customer-portal-header',
  templateUrl: './new-end-customer-portal-header.component.html',
  styleUrls: ['./new-end-customer-portal-header.component.scss']
})
export class EndCustomerPortalHeaderComponent {
  loggedInUser!: string;
  isLandingPage!: boolean;
  selectedLanguage = '';
  locale = 'de';
  activeItem = '';
  isLoggedIn = false;
  loginDisplay = false;
  isDesktop = true;
  closeResult = '';
  sidenavWidth: number = 0;
  sideNavZindex = -1;
  showSimpleHeader = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private userService: UserService,
    private dataShareService: DataShareService,
    private storage: StorageService,
    private toasterService: ToastrService,
    private verificationService: VerificationService
    // @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    // private authService: MsalService,
  ) {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
    this.userService.fetchIsLoggedInStatus.subscribe(status => this.isLoggedIn = status);
    this.userService.fetchUpatedUserName.subscribe(username => {
      this.loggedInUser = username;
    });
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if (this.router.url.toString().includes('home')) {
        this.isLandingPage = true;
      } else {
        this.isLandingPage = false;
      }
      if (this.router.url.toString().includes('home') || this.router.url.toString().includes('auth')) {
        this.isLoggedIn = false;
      } else {
        this.isLoggedIn = true;
      }
    });
    this.dataShareService.handleLocaleTracker.subscribe((res: string) => {
      if (res != null && res != "") {
        this.selectLanguage(res);
      }
    });

    this.isDesktop = !this.verificationService.detectMob() && !this.verificationService.detectTab();
    if (window.location.href.includes('ees')) {
      this.showSimpleHeader = true;
    }
  }

  ngOnInit() {
    this.setLocaleByBrowser();
  }
  setLocaleByBrowser() {

    let localeInStorage = this.storage.getItem('locale');
    if (!localeInStorage) {
      let locale = this.translate.getBrowserLang();
      if (locale == 'de') {
        this.locale = locale;
      } else {
        if (!window.location.href.includes('ees')) {
          this.toasterService.info("Willkommen bei der deutschen Version unseres TESVOLT-Konfigurators. Wir arbeiten bereits an Ihrer Sprachversion.");
        }
        this.locale = 'de';
      };
      this.storage.setItem('locale', this.locale);
    } else {
      this.locale = localeInStorage;
    }
    this.selectedLanguage = 'language.german';
    switch (this.locale) {
      case 'en': this.selectedLanguage = 'language.english';
        break;
      case 'de': this.selectedLanguage = 'language.german';
        break;
      // case 'it': this.selectedLanguage = 'language.italian';
      //   break;
      // case 'nl': this.selectedLanguage = 'language.dutch';
      //   break;
    }
    this.dataShareService.setLocale(this.locale);
    this.translate.use(this.locale);
  }
  selectLanguage(val: string) {
    if (val === 'de') {
      this.selectedLanguage = 'language.german';
      this.locale = 'de'
    }
    // else if (val == 'it') {
    //   this.selectedLanguage = 'language.italian';
    //   this.locale = 'it';
    // }
    // else if (val == 'nl') {
    //   this.selectedLanguage = 'language.dutch';
    //   this.locale = 'nl';
    // }
    else {
      this.selectedLanguage = 'language.english';
      this.locale = 'en';
    }

    this.dataShareService.setLocale(this.locale);
    this.storage.setItem('locale', this.locale);
    this.translate.use(this.locale);
  }
  setActive(option: string) {
    this.activeItem = option;
  }
  isActive(option: string) {
    return option == this.activeItem
  }

  signUp() {
    this.router.navigate(['sign-up']);
  }
  @HostListener('window:orientationchange')
  closeNav() {
    this.dataShareService.navExpanded.next(false);
    this.sidenavWidth = 0;
    this.sideNavZindex = -1;
  }
  toggleNav(): void {
    // alert("innerHeight:" + window.innerHeight + ", innerWidth: " + window.innerWidth)
    if (window.innerHeight > window.innerWidth) {
      this.dataShareService.navExpanded.next(this.sidenavWidth === 0 ? true : false);
    }
    // alert(this.dataShareService.navExpanded.subscribe((val) => alert(val)))
    this.sidenavWidth = this.sidenavWidth === 0 ? 320 : 0;
    this.sideNavZindex = this.sideNavZindex == -1 ? 1 : -1;
  }


}
