import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "end-customer-portal-sidebar",
  templateUrl: "./end-customer-portal-sidenav.component.html",
  styleUrls: ["./end-customer-portal-sidenav.component.scss"]
})
export class EndCustomerPortalSidebarComponent {
  @Input() isExpanded: boolean = true;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();

  handleSidebarToggle() { this.toggleSidebar.emit(!this.isExpanded); }

}
