<div class="recommended-product-wrapper d-flex justify-content-center align-items-center p-5">
    <div class="left-side col-lg-5 col-md-12">
        <div [ngClass]="!toBePrinted ? 'table-wrapper' : 'report-design'">
            <table class="my-4 font-color-black" id="table3">
                <tr *ngFor="let feature of featureList.get(productName)">
                    <td><img class="plus-icon" src="assets/plus.svg" alt=""> </td>
                    <td class="desc">{{feature}}</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="right-side col-lg-5 col-md-12" [ngClass]="toBePrinted ? 'report-div' : ''">
        <img [src]="getImageUrl(imageName)" class="right-banner-img" [ngClass]="toBePrinted ? 'report' : ''">
    </div>
</div>