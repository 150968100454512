import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { ConfiguratorService } from "../configurator/utility/configurator.service";
import { AppConstants } from "../core/constants";

@Component({
    selector: 'end-customer-portal-home',
    templateUrl: './home-lite.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {
    ppUrl = AppConstants.Partner_Portal_URL;
    constructor(private router: Router, private configuratorService: ConfiguratorService) { }
    openConfigurator() {
        this.configuratorService.isRunningInWindow = false;
        this.router.navigate(['configurator']);
    }
}
