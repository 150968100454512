import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AppConstants } from "src/app/core/constants";
import { ConfiguratorService } from "../utility/configurator.service";
import { DataShareService } from "src/app/services/data-share.service";
import { VerificationService } from "../../auth/verification/verification.service";

@Component({
    selector: 'end-customer-portal-configurator-welcome',
    templateUrl: './configurator-welcome.component.html',
    styleUrls: ['./configurator-welcome.component.scss']
})
export class ConfiguratorWelcomeComponent {
    partnerPortalLink = AppConstants.Partner_Portal_URL;
    isDesktop = false;
    isMobile = false;
    isTablet = false;

    videoSrcFwMobile: string = "assets/videos/lead_gen_start_mp_01.mp4"
    videoSrcFwTablet: string = "assets/videos/tablet/lead_gen_start_mt_01.mp4"
    constructor(private configurationService: ConfiguratorService, private router: Router, private datashareservice: DataShareService, private verificationService: VerificationService) {
        this.configurationService.startConfiguration();
        this.isMobile = this.verificationService.detectMob();
        this.isTablet = this.verificationService.detectTab();
        this.isDesktop = !this.isMobile && !this.isTablet;
    }
    startConfiguration() {
        this.datashareservice.scrollToNav.next();
        if (this.configurationService.isRunningInWindow) {
            this.configurationService.stepToShowInWindow = 1;
        } else {
            this.router.navigate(['configurator/step1']);
        }
    }
}
