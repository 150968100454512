<div class="container">
  <ng-container *ngIf="showchart">
    <div *ngIf="simuplexResponse; else noDataFound" class="pt-3 pt-lg-0"
      [ngClass]="{'configurator-body-desktop' : isDesktop}">
      <div class="top-box p-0">
        <h1 class="page-heading text-center font-color-white">{{currentSlideTitle}}
        </h1>
      </div>
      <div class="graph-box">
        <ngb-carousel (slide)="onSlide($event)" class="fixed-height" [showNavigationIndicators]="false" [interval]="0"
          #carousel>
          <ng-template ngbSlide [id]="'graph'" class="fixed-height" *ngIf="isDesktop">
            <div class="d-flex flex-row justify-content-evenly  p-5">
              <div class="col-4">
                <end-customer-pie-chart [value1]="selfSufficiencyRatio" [value2]="100 - selfSufficiencyRatio"
                  [mainHeading]="'resultpage.piechart1.heading'" [subHeading]="'resultpage.piechart1.subheading'"
                  [de_heading1]="'Autarkie'" [de_heading2]="'Netzbezug'" [en_heading1]="'Self sufficiency'"
                  [en_heading2]="'Grid Energy Draw'" (onBatterySwitchChange)="getSelfSufficiencyRatioChartData($event)"
                  [color1]="'#00ACC2'" [color2]="'#005762'"
                  [increasedRatio]="increasedSelfSufficiencyRatio"></end-customer-pie-chart>
              </div>
              <div class="col-4">
                <end-customer-pie-chart [value1]="directConsumption" [value2]="gridFeedInPortion"
                  [de_heading1]="'Eigenverbrauch'" [de_heading2]="'Netzeinspeisung'" [en_heading1]="'Self consumption'"
                  [subHeading]="'resultpage.piechart2.subheading'" [mainHeading]="'resultpage.piechart2.heading'"
                  [en_heading2]="'Grid feed'" (onBatterySwitchChange)="getSelfConsumptionChartData($event)"
                  [color1]="'#9DBF26'" [color2]="'#4F6013'"
                  [increasedRatio]="increasedSelfConsumptionRatio"></end-customer-pie-chart>
                <!--Fix increase ratio-->
              </div>
            </div>
          </ng-template>
          <ng-template ngbSlide [id]="'graph1'" class="fixed-height" *ngIf="isMobile || isTablet">
            <div>
              <div>
                <end-customer-pie-chart [value1]="selfSufficiencyRatio" [value2]="100 - selfSufficiencyRatio"
                  [subHeading]="'resultpage.piechart1.subheading'" [mainHeading]="'resultpage.piechart1.heading'"
                  [de_heading1]="'Autarkie'" [de_heading2]="'Netzbezug'" [en_heading1]="'Self sufficiency'"
                  [en_heading2]="'Grid Energy Draw'" (onBatterySwitchChange)="getSelfSufficiencyRatioChartData($event)"
                  [color1]="'#00ACC2'" [color2]="'#005762'"
                  [increasedRatio]="increasedSelfSufficiencyRatio"></end-customer-pie-chart>
              </div>

            </div>
          </ng-template>
          <ng-template ngbSlide [id]="'graph2'" class="fixed-height" *ngIf="isMobile|| isTablet">
            <div>
              <div>
                <end-customer-pie-chart [value1]="directConsumption" [value2]="gridFeedInPortion"
                  [subHeading]="'resultpage.piechart2.subheading'" [mainHeading]="'resultpage.piechart2.heading'"
                  [de_heading1]="'Eigenverbrauch'" [de_heading2]="'Netzeinspeisung'" [en_heading1]="'Self consumption'"
                  [en_heading2]="'Grid feed'" (onBatterySwitchChange)="getSelfConsumptionChartData($event)"
                  [color1]="'#9DBF26'" [color2]="'#4F6013'"
                  [increasedRatio]="increasedSelfConsumptionRatio"></end-customer-pie-chart>
                <!--Fix increase ration-->
              </div>
            </div>
          </ng-template>
          <ng-template ngbSlide [id]="'data'" class="fixed-height">
            <end-customer-response-table [productName]="simuplexResponse.productName"
              [imageName]="simuplexResponse.productImage"></end-customer-response-table>
          </ng-template>
          <ng-template ngbSlide [id]="'inputs'" class="fixed-height">
            <end-customer-input-table [application1]="configuratorService.application1"
              [application2]="configuratorService.application2" [application3]="configuratorService.application3"
              [businessSector]="configuratorService.businessSector"></end-customer-input-table>
          </ng-template>
        </ngb-carousel>

        <hr class="hr mx-auto">
        <!-- Modal -->
        <div #modalWindow class="modal fade" id="mainWarningModal" tabindex="-1" aria-labelledby="warningModal"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title text-center" id="warningModal">Achtung!</h5>
                <button #closeButton type="button" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="modal-content-wrapper d-flex flex-column justify-content-center">
                  <img class="warning-icon text-center mx-auto pb-3" src="assets/img/warning.svg" alt="">
                  <p class="modal-text">Wenn Sie die Seite verlassen, werden Ihre aktuellen Berechnungen gelöscht. Sie
                    können dann eine neue Berechnung starten!</p>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
                <button (click)="restartConfiguration()" type="button" class="btn btn-primary">Fortfahren</button>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex  justify-content-center col-lg-6 col-md-12 mx-auto text-center py-5 reduced-padding"
          [ngClass]="{'mob-button flex-column': isMobile, 'flex-row': isDesktop||isTablet}">
          <button data-bs-toggle="modal" data-bs-target="#mainWarningModal" class="resent-btn btn btn-primary mx-auto ">
            <img *ngIf="isDesktop || isTablet" src="assets/img/configurator/configuire-chart-icon-white.svg" alt="">
            {{ "marketingForm.rerunTheConfigurator" | translate}}
            <img *ngIf="isMobile" src="assets/img/configurator/configuire-chart-icon-white.svg" alt="">
          </button>
          <button translate class="resent-btn btn btn-primary mx-auto" (click)="redirectToPage()"
            [ngClass]="{'extra-margin': isMobile}">
            <span>{{"marketingForm.btnTitle1" | translate}}</span> <img class="" src="/assets/img/right-arrow.svg"
              style=" filter: brightness(0) invert(1)" alt="" alt="">
            <!-- <span *ngIf="!this.userService.loggedInUser">{{"marketingForm.downloadRegister" | translate}}</span> -->
          </button>

        </div>
      </div>
    </div>

    <ng-template #noDataFound>
      <div class="no-data-found d-flex flex-column justify-content-center align-items-center py-5">
        <img [src]="imgAddress" alt="" *ngIf="isDesktop">
        <img [src]="imgAddressMob" alt="" *ngIf="isMobile">
        <h2 class="text-center green-color-font" [innerHTML]="'successPageNoProductHeading' | translate"></h2>
        <p class="mt-2 base-paragraph text-center">{{"successPageNoProductMsg" | translate}}</p>
        <button (click)="restartConfiguration()" class="resent-btn btn btn-primary download-report-btn mt-5">
          {{ "noproductscreen.rerunbuttom" | translate}}
          <img src="assets/img/configurator/configuire-chart-icon-white.svg" alt="">
        </button>
      </div>
    </ng-template>
  </ng-container>
</div>