import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Options } from "ngx-slider-v2/options";
import { ConfiguratorService } from "../utility/configurator.service";
import { DecimalPipe } from "@angular/common";
import { filter } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { VerificationService } from "../../auth/verification/verification.service";

@Component({
  selector: 'end-customer-portal-configurator-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss']
})
export class Step2Component implements OnChanges {
    isDesktop = false;
    isMobile = false;
    isTablet = false;
    videoSrcFwMobile: string = "assets/videos/lead_gen_consumption_mp_01.mp4"
    videoSrcFwTablet: string = "assets/videos/tablet/lead_gen_consumption_mt_01.mp4"

  constructor(public configuratorService: ConfiguratorService,
    private router: Router, private verificationService: VerificationService,
    private _decimalPipe: DecimalPipe) {
    this.videoSrc = this.videoSrcFw;
    this.isMobile = this.verificationService.detectMob();
    this.isTablet = this.verificationService.detectTab();
    this.isDesktop = !this.isMobile && !this.isTablet;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.playVideoWithFrameCount();
    });
  }
  @Input() visible!: boolean;
  videoSrcFw = "assets/videos-rel1/lead_gen_animations_solo_new_x01/video/leadgen_consumption_desktop_v3.mp4";
  // videoSrcBw = "assets/video/tv_con_consumption_backward.mp4";
  videoSrc!: string;

  value: number = this.configuratorService.configurator.electricityConsumption / 1000;
  oldValue!: number;
  options: Options = {
    floor: 10,
    ceil: 2000,
    // showTicks: true,
    logScale: true,
    step: 10,
    showSelectionBar: true,
    translate: (value: number): string => {
      if (value == 2000) {
        return '2 Mio.';
      } else if (value == 10) {
        return this._decimalPipe.transform(value * 1000, '', 'de-DE')?.toString() ?? '';
      } else {
        return '';
      }
    }

  };


  ngOnChanges(simpleChanges: SimpleChanges) {

    if (simpleChanges['visible'].currentValue == true) {
      this.value = this.configuratorService.configurator.electricityConsumption / 1000;
      this.playVideoWithFrameCount();
    }
  }
  playVideoWithFrameCount() {
    this.videoSrc = this.videoSrcFw;
    // this.oldValue = Math.floor(this.value / (398 / 8));
    // const video = document.getElementById("video2");
    // (video as any).play();
    // setTimeout(() => {
    //   (video as any).currentTime = 0;
    //   (video as any).play();
    //   setTimeout(() => {
    //     (video as any).pause();
    //   }, (((this.value) / (398 / 8)) * 1000)); // Adjusted timeout based on frame count
    // }, 100); // Adjusted timeout based on frame count
    const video = document.getElementById("video2");
    (video as any).play();
    setTimeout(() => {
      (video as any).currentTime = 0;
      (video as any).play();
    }, 300); // Adjusted timeou
  }


  bindValue() {
    this.configuratorService.configurator.electricityConsumption = this.value * 1000; // the slider is reduced by 1000 times for better performance
    // const video = document.getElementById("video2");
    // const video1 = document.getElementById("video2a");

    // const segmentSize = 398 / 8;
    // const triggerIndex = Math.floor(this.value / segmentSize);

    // if (triggerIndex !== this.oldValue) {
    //   this.videoSrc = triggerIndex > this.oldValue ? this.videoSrcFw : this.videoSrcBw;
    //   let startTime;
    //   if (triggerIndex > this.oldValue) {
    //     startTime = triggerIndex;
    //     this.oldValue = startTime;
    //     (video as any).currentTime = startTime;
    //     (video as any).play();
    //     setTimeout(() => {
    //       (video as any).pause();
    //     }, 1000);
    //   } else {
    //     startTime = (video1 as any).duration - (triggerIndex + 2);
    //     this.oldValue = triggerIndex;
    //     (video1 as any).currentTime = startTime;
    //     (video1 as any).play();

    //     setTimeout(() => {
    //       (video1 as any).pause();
    //     }, 1000);
    //   }

    // }
  }

}
