import { Component } from "@angular/core";
import { ConfiguratorService } from "../utility/configurator.service";

@Component({
    selector: 'end-customer-portal-configurator-container',
    templateUrl: './configurator-container.component.html',
    styleUrls: ['./configurator-container.component.scss']
}) export class ConfiguratorContainerComponent {
    constructor(public configuratorService: ConfiguratorService) {
        this.configuratorService.isRunningInWindow = true;
    }
}