import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Options } from "ngx-slider-v2/options";
import { PVSystemAccess } from "../utility/PV-system-access.enum";
import { ConfiguratorService } from "../utility/configurator.service";
import { filter } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { VerificationService } from "../../auth/verification/verification.service";

@Component({
  selector: 'end-customer-portal-configurator-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss']
})
export class Step3Component implements OnChanges {
  @Input() visible!: boolean;
  videoSrc!: string;
  videoSrcFw: string = "assets/videos-rel1/lead_gen_animations_solo_new_x01/video/leadgen_generation_desktop_v3.mp4";
  // videoSrcBw: string = "assets/video/tv_con_pv_backward.mp4";
  isDesktop = false;
  isMobile = false;
  isTablet = false;
  videoSrcFwMobile: string = "assets/videos/lead_gen_pv_mp_01.mp4"
  videoSrcFwTablet: string = "assets/videos/tablet/lead_pv_generation_mt_01.mp4"

  PVSystemAccess = PVSystemAccess;
  options: Options = {
    floor: 0,
    ceil: 1000,
    showSelectionBar: true,
    step: 10,
    translate: (value: number): string => {
      if (value == 0 || value == 1000) {
        return value.toString();
      } else {
        return '';
      }
    }
  };
  // oldValue = 0;

  dontHavePV: boolean = false;
  planningForPV: boolean = false;
  // planningForAnotherSource: boolean = false;

  constructor(public configurationService: ConfiguratorService,
    private verificationService: VerificationService,
    private router: Router,) {
    this.videoSrc = this.videoSrcFw;
    this.isMobile = this.verificationService.detectMob();
    this.isTablet = this.verificationService.detectTab();
    this.isDesktop = !this.isMobile && !this.isTablet;
    // this.oldValue = Math.floor(this.configurationService.configurator.PVSystemPower / (250 / 8));
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.playVideoWithDelay("video3", 300);
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['visible'].currentValue == true) {
      this.playVideoWithDelay("video3", 300);
    }

    this.dontHavePV = false;
    this.planningForPV = false;
  }

  playVideoWithDelay(videoId: string, delay: number) {
    setTimeout(() => {
      const video = document.getElementById(videoId);
      let startTime = 0;
      (video as any).currentTime = startTime;
      (video as any).play();
      // setTimeout(() => {
      //   (video as any).pause();
      // }, 20 * (250 / 10));
      // (video as any).play();
    }, delay);
  }



  unsetNoPVForPositiveVal() {
    if (this.configurationService.configurator.PVSystemPower > 0) {
      this.configurationService.configurator.PVSystemAccess = undefined;
      this.dontHavePV = false;
    }
  }

  // bindValue() {
  //   const video = document.getElementById("video3");
  //   const video1 = document.getElementById("video3a");
  //   const segmentSize = 250 / 8;
  //   const triggerIndex = Math.floor(this.configurationService.configurator.PVSystemPower / segmentSize);
  //   if (triggerIndex !== this.oldValue) {
  //     this.videoSrc = triggerIndex > this.oldValue ? this.videoSrcFw : this.videoSrcBw;
  //     let startTime;
  //     if (triggerIndex > this.oldValue) {
  //       startTime = triggerIndex;
  //       this.oldValue = startTime;
  //       (video as any).currentTime = startTime;
  //       (video as any).play();
  //       setTimeout(() => {
  //         (video as any).pause();
  //       }, 1000);
  //     } else {
  //       startTime = (video1 as any).duration - (triggerIndex + 1);
  //       this.oldValue = triggerIndex;
  //       (video1 as any).currentTime = startTime;
  //       (video1 as any).play();
  //       setTimeout(() => {
  //         (video1 as any).pause();
  //       }, 1000);
  //     }
  //   }
  // }
  getSelectedItem() {
    if (this.dontHavePV) {
      if (!this.configurationService.configurator?.PVSystemAccess?.includes(PVSystemAccess.dontHave)) {
        this.configurationService.configurator.PVSystemAccess?.push(PVSystemAccess.dontHave)
      }
    }
    if (this.planningForPV) {
      if (!this.configurationService.configurator?.PVSystemAccess?.includes(PVSystemAccess.planning)) {
        this.configurationService.configurator.PVSystemAccess?.push(PVSystemAccess.planning)
      }
    }
    if (!this.dontHavePV) {
      const index = this.configurationService.configurator.PVSystemAccess?.indexOf(PVSystemAccess.dontHave);
      if (index !== undefined && index !== -1) {
        this.configurationService.configurator.PVSystemAccess?.splice(index, 1);
      }
    }
    if (!this.planningForPV) {
      const index = this.configurationService.configurator.PVSystemAccess?.indexOf(PVSystemAccess.planning);
      if (index !== undefined && index !== -1) {
        this.configurationService.configurator.PVSystemAccess?.splice(index, 1);
      }
    }

  }

}
