import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs";
import { BusinessArea } from "../utility/business-area.enum";
import { ConfiguratorService } from "../utility/configurator.service";
import { VerificationService } from "src/app/auth/verification/verification.service";


@Component({
  selector: 'end-customer-portal-configurator-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss']
})
export class Step1Component implements OnInit {
  @Input() visible!: boolean;
  BusinessArea = BusinessArea;
  someOtherBusinessArea = '';
  entryTime!: Date;
  exitTime!: Date;
  someOtherBusinessAreaCheckbox = false;
  isMobile = false;
  isTablet = false;
  carouselData: any[];

  getFinalCarouselData(businessArea: BusinessArea) {
    this.carouselData.forEach(item => {
      item.selected = item.businessArea === businessArea;
    });

  }

  constructor(public configurationService: ConfiguratorService, private router: Router, private verificationService: VerificationService) {
    // this.router.events.pipe(
    //   filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    //   map(e => {
    //     this.routeChanged()
    //   })
    // );
    this.isMobile = this.verificationService.detectMob();
    this.isTablet = this.verificationService.detectTab();
    this.carouselData = [{
      businessArea: BusinessArea.smallBusiness,
      selected: true,
      title: "configurator.smallBusiness.headingTitle",
      sliderContents: [
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_mittelstand_03.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_mittelstand_tablet_portrait_02.jpg" : "../../../assets/img/configurator/step-1/small-business/1.jpg",
          description: 'configurator.smallBusiness1.title',
          nextBtn: 'configurator.next',
        },
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_mittelstand_01.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_mittelstand_tablet_portrait_03.jpg" : "../../../assets/img/configurator/step-1/small-business/2.jpg",
          description: 'configurator.smallBusiness2.title',
          nextBtn: 'configurator.next'
        },
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_mittelstand_02.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_mittelstand_tablet_portrait_01.jpg" : "../../../assets/img/configurator/step-1/small-business/3.jpg",
          description: 'configurator.smallBusiness3.title',
          nextBtn: 'configurator.next'
        }
      ]
    },
    {
      businessArea: BusinessArea.logistics,
      selected: false,
      title: "configurator.bigBusiness.headingTitle",
      sliderContents: [
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_logistik_03.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_logistik_tablet_portrait_03.jpg" : "../../../assets/img/configurator/step-1/big-business/1.jpg",
          description: 'configurator.bigBusiness1.title',
          nextBtn: 'configurator.next'
        },
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_logistik_01.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_logistik_tablet_portrait_02.jpg" : "../../../assets/img/configurator/step-1/big-business/2.jpg",
          description: 'configurator.bigBusiness2.title',
          nextBtn: 'configurator.next'
        },
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_logistik_02.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_logistik_tablet_portrait_01.jpg" : "../../../assets/img/configurator/step-1/big-business/3.jpg",
          description: 'configurator.bigBusiness3.title',
          nextBtn: 'configurator.next'
        }
      ]
    },
    {
      businessArea: BusinessArea.industry,
      selected: false,
      title: "configurator.industry.headingTitle",
      sliderContents: [
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_industrie_02.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_industrie_tablet_portrait_02.jpg" : "../../../assets/img/configurator/step-1/industry/1.jpg",
          description: 'configurator.industry1.title',
          nextBtn: 'configurator.next'
        },
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_industrie_01.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_industrie_tablet_portrait_03.jpg" : "../../../assets/img/configurator/step-1/industry/2.jpg",
          description: 'configurator.industry2.title',
          nextBtn: 'configurator.next'
        },
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_industrie_03.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_industrie_tablet_portrait_01.jpg" : "../../../assets/img/configurator/step-1/industry/3.jpg",
          description: 'configurator.industry3.title',
          nextBtn: 'configurator.next'
        }
      ]
    },
    {
      businessArea: BusinessArea.farming,
      selected: false,
      title: "configurator.warehousing.headingTitle",
      sliderContents: [
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_landwirtschaft_01.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_landwirtschaft_tablet_portrait_03.jpg" : "../../../assets/img/configurator/step-1/farming/1.jpg",
          description: 'configurator.farming1.title',
          nextBtn: 'configurator.next'
        },
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_landwirtschaft_02.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_landwirtschaft_tablet_portrait_01.jpg" : "../../../assets/img/configurator/step-1/farming/2.jpg",
          description: 'configurator.farming2.title',
          nextBtn: 'configurator.next'
        },
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_landwirtschaft_03.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_landwirtschaft_tablet_portrait_02.jpg" : "../../../assets/img/configurator/step-1/farming/3.jpg",
          description: 'configurator.farming3.title',
          nextBtn: 'configurator.next'
        }
      ]
    },
    {
      businessArea: BusinessArea.somethingElse,
      selected: false,
      title: "configurator.bigBusiness.headingTitle",
      sliderContents: [
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_logistik_03.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_logistik_tablet_portrait_03.jpg" : "../../../assets/img/configurator/step-1/big-business/1.jpg",
          description: 'configurator.bigBusiness1.title',
          nextBtn: 'configurator.next'
        },
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_logistik_01.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_logistik_tablet_portrait_02.jpg" : "../../../assets/img/configurator/step-1/big-business/2.jpg",
          description: 'configurator.bigBusiness2.title',
          nextBtn: 'configurator.next'
        },
        {
          image: this.isMobile ? "../../../assets/img/configurator/mobile/usecases_mobile_portrait_logistik_02.jpg" : this.isTablet ? "../../../assets/img/configurator/tablet/usecase_logistik_tablet_portrait_01.jpg" : "../../../assets/img/configurator/step-1/big-business/3.jpg",
          description: 'configurator.bigBusiness3.title',
          nextBtn: 'configurator.next'
        }
      ]
    },
    ];

  }

  ngOnInit() {
    this.someOtherBusinessAreaCheckbox = this.configurationService.configurator.businessArea == BusinessArea.somethingElse;
    this.someOtherBusinessArea = this.configurationService.configurator.businessArea == BusinessArea.somethingElse ? this.configurationService.configurator.businessAreaSomethingElse ? this.configurationService.configurator.businessAreaSomethingElse : '' : '';
  }
  ngOnChanges(simpleChanges: SimpleChanges) {

    if (simpleChanges['visible'].currentValue == true) {
      this.carouselData[0].selected = true;
      this.entryTime = new Date();
    }
  }
  checkIfSomethingElseIsSet() {
    if (this.someOtherBusinessAreaCheckbox) {
      this.setBusiness(BusinessArea.somethingElse)
    } else {
      this.configurationService.configurator.businessArea = BusinessArea.smallBusiness;
      this.configurationService.configurator.businessAreaSomethingElse = undefined;
      this.someOtherBusinessArea = '';
      this.getFinalCarouselData(BusinessArea.smallBusiness)
    }
  }
  setBusiness(businessArea: BusinessArea) {
    this.configurationService.configurator.businessArea = businessArea;
    this.configurationService.configurator.step1UserInteracted = true;
    if (businessArea == BusinessArea.somethingElse) {
      // this.configurationService.configurator.businessAreaSomethingElse = this.someOtherBusinessArea;
      this.someOtherBusinessAreaCheckbox = true;
    } else {
      this.someOtherBusinessAreaCheckbox = false;
    }
    this.getFinalCarouselData(businessArea)
  }

  // routeChanged() {
  //   alert("Exit");
  //   this.exitTime = new Date();
  //   this.configurationService.configurator.step1Duration = this.exitTime.getTime() - this.entryTime.getTime();
  // }
}

