import { Component, Input, SimpleChanges } from '@angular/core';
import { Options } from "ngx-slider-v2/options";
import { ConfiguratorService } from "../utility/configurator.service";
import { ElectricCarAccess } from "../utility/electric-car-access.enum";
import { VerificationService } from "src/app/auth/verification/verification.service";

@Component({
  selector: 'end-customer-portal-configurator-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss']
})
export class Step4Component {
  @Input() visible!: boolean;
  isDesktop = false;
  isMobile = false;
  isTablet = false;
  electricCarAccessMax = false;
  electricCarAccessMin = false;
  videoSrc!: string;
  videoSrcFw: string = "assets/videos-rel1/lead_gen_animations_solo_new_x01/video/leadgen_electric_cars_desktop_v3.mp4";
  videoSrcFwMobile: string = "assets/videos/lead_gen_electriccars_mp_01.mp4"
  videoSrcFwTablet: string = "assets/videos/tablet/lead_gen_electric_cars_mt_01.mp4"


  constructor(public configurator: ConfiguratorService, private verificationService: VerificationService) {
    this.configurator.configurator.electricCarChargingStations = 2;
    this.videoSrc = this.videoSrcFw;
    this.isMobile = this.verificationService.detectMob();
    this.isTablet = this.verificationService.detectTab();
    this.isDesktop = !this.isMobile && !this.isTablet;
  }
  ElectricCarAccess = ElectricCarAccess;

  mileageHigh = this.configurator.configurator.electricCarMileageHigh / 1000;
  mileageLow = this.configurator.configurator.electricCarMileageLow / 1000;

  options: Options = {
    floor: 0,
    ceil: 20,
    showSelectionBar: true,
    translate: (value: number): string => {
      if (value == 0 || value == 20) {
        return value.toString();
      } else {
        return '';
      }
    }
  };


  mileageOptions: Options = {
    floor: 0,
    ceil: 100,
    showSelectionBar: true,
    translate: (value: number): string => {
      if (value == 100) {
        return value + '.000';
      } else if (value == 0) {
        return '0';
      } else {
        return '';
      }
    }
  };
  oldValue!: number;

  userInteracted() {
    this.configurator.configurator.step4UserInteracted = true;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['visible'].currentValue == true) {
      this.mileageHigh = this.configurator.configurator.electricCarMileageHigh / 1000;
      this.mileageLow = this.configurator.configurator.electricCarMileageLow / 1000;

      const video = document.getElementById("video4");
      // this.oldValue = 2;
      setTimeout(() => {
        (video as any).currentTime = 0;
        (video as any).play();
        // setTimeout(() => {
        // (video as any).pause();
        // }, (this.configurator.configurator.electricCarChargingStations) * 1000 // Adjusted timeout based on frame count
        // )
      }, 300); // Adjusted timeout based on frame count
    }
  }
  startTime!: number;
  endTime!: number;
  // bindElectricCarData() {
  //   const video = document.getElementById("video4");
  //   const video1 = document.getElementById("video4a");
  //   const segmentSize = 1;
  //   const triggerIndex = Math.floor(this.configurator.configurator.electricCarChargingStations / segmentSize);

  //   if (triggerIndex !== this.oldValue) {
  //     this.videoSrc = triggerIndex > this.oldValue ? this.videoSrcFw : this.videoSrcBw;
  //     let startTime;
  //     if (triggerIndex > this.oldValue) {
  //       startTime = triggerIndex - 1;
  //       this.oldValue = triggerIndex;
  //       (video as any).currentTime = startTime;
  //       (video as any).play();

  //       setTimeout(() => {
  //         (video as any).pause();
  //       }, 1000);
  //     } else {
  //       startTime = (video1 as any).duration - (this.configurator.configurator.electricCarChargingStations + 1);
  //       this.oldValue = triggerIndex;
  //       (video1 as any).currentTime = startTime;
  //       (video1 as any).play();

  //       setTimeout(() => {
  //         (video1 as any).pause();
  //       }, 1000);
  //     }
  //   }
  // }

  bindValue() {
    setTimeout(() => {
      this.configurator.configurator.step4UserInteracted = true;
      if (this.mileageLow == 100 && this.mileageHigh == 100) {
        this.electricCarAccessMax = true;
      }
      if (!((this.mileageLow == 0 && this.mileageHigh == 0) ||
        (this.mileageLow == 100 && this.mileageHigh == 100))
        && (this.configurator.configurator.electricCarAccess == ElectricCarAccess.noElectricVehicle || this.configurator.configurator.electricCarAccess == ElectricCarAccess.drivesMoreThan100000)) {
        this.configurator.configurator.electricCarAccess = undefined;
        this.electricCarAccessMax = false;
        this.electricCarAccessMin = false;
      }

      this.configurator.configurator.electricCarMileageHigh = this.mileageHigh * 1000; // the slider is reduced by 1000 times for better performance
      this.configurator.configurator.electricCarMileageLow = this.mileageLow * 1000; // the slider is reduced by 1000 times for better performance
    }, 200);

  }

  setSliderToMax() {
    this.configurator.configurator.electricCarAccess = ElectricCarAccess.drivesMoreThan100000;
    this.electricCarAccessMin = false
    this.mileageHigh = 100;
    this.mileageLow = 100;
    this.bindValue();
  }

  setSliderToMin() {
    this.mileageHigh = 0;
    this.mileageLow = 0;
    this.configurator.configurator.electricCarChargingStations = 0;
    this.configurator.configurator.electricCarAccess = ElectricCarAccess.noElectricVehicle;
    this.electricCarAccessMax = false
    this.bindValue();
  }
}
