<div class="main-wrapper" [ngClass]="{'overflow-hidden':  sidebarExpanded}">
  <end-customer-portal-header class="fixed-header"></end-customer-portal-header>

  <!-- <end-customer-portal-sidebar [isExpanded]="dataShareService.navExpanded" *ngIf="isLoggedIn"
    (toggleSidebar)="handleToggleSidebar()"></end-customer-portal-sidebar> -->
  <main #headerscrolltarget class="main-block">
    <ngx-spinner *ngIf="dataShareService.spinnerVisible" bdColor=" #ffffff" color="#a2c235" type="ball-scale-multiple"
      [fullScreen]="false">{{dataShareService.spinnerText}}</ngx-spinner>
    <router-outlet class="router-outlet"></router-outlet>
  </main>
  <end-customer-portal-footer *ngIf="showFooter"></end-customer-portal-footer>
</div>