import { Component, Input, OnInit } from "@angular/core";
import { BusinessArea } from "../utility/business-area.enum";
import { ConfiguratorService } from "../utility/configurator.service";
import { TranslateService } from "@ngx-translate/core";
import { VerificationService } from "../../auth/verification/verification.service";

@Component({
  selector: 'end-customer-input-table',
  templateUrl: './input-table.component.html', host: {
    '[class.successpage]': '!toBePrinted'
  },
  styleUrls: ['./input-table.component.scss']
})
export class InputTableComponent implements OnInit {
  constructor(public configuratorService: ConfiguratorService,
    public translateService: TranslateService,
    public vs: VerificationService,) { }
  inputImageBasePath: string = 'assets/success_page_media/';
  inputImageAddress = "";
  imageTag = 'businessArea';
  @Input() application1: string | undefined;
  @Input() application2: string | undefined;
  @Input() application3: string | undefined;
  @Input() businessSector !: string;
  @Input() toBePrinted: boolean = false;
  isMobile = false;
  isTab = false;
  isDesktop = false;


  ngOnInit() {
    this.isMobile = this.vs.detectMob();
    this.isTab = this.vs.detectTab();
    this.isDesktop = !this.isMobile && !this.isTab;
    if (this.toBePrinted) {
      this.imageTag = 'application1';
    } else {
      this.imageTag = 'businessArea';
    }
  }

  getKnowledgeLevel(knowledgeLevel: number) {
    switch (knowledgeLevel) {
      case 1: return 'configurator.step5.knowledgelevel.case1';
      case 2: return 'configurator.step5.knowledgelevel.case2';
      case 3: return 'configurator.step5.knowledgelevel.case3';
      case 4: return 'configurator.step5.knowledgelevel.case4';
      default: return 'configurator.step5.knowledgelevel.case5';
    }
  }

  setInputImageAddress() {
    if (this.isMobile) {
      if (this.toBePrinted) {
        if (this.application1?.toLowerCase() == "peak load shaving") {
          return this.inputImageBasePath + 'Applications-Mobile/successpage_peak-shaving_preview_01.jpg';
        } else if (this.application1?.toLowerCase() == "self consumption optimization") {
          return this.inputImageBasePath + 'Applications-Mobile/successpage_self-con-opt_preview_01.jpg';
        } else if (this.application1?.toLowerCase() == "backup power") {
          return this.inputImageBasePath + 'Applications-Mobile/successpage_backup-power_preview_01.jpg';
        }
      } else {
        switch (this.imageTag) {
          case 'businessArea':
            switch (this.configuratorService.configurator?.businessArea) {
              case BusinessArea.logistics:
                return this.inputImageBasePath + 'Business_Cases_Mobile/success_page_business-sector_bigbusiness_01.jpg';
              case BusinessArea.smallBusiness:
              case BusinessArea.somethingElse:
                return this.inputImageBasePath + 'Business_Cases_Mobile/success_page_business-sector_smalllbusines_01.jpg';
              case BusinessArea.farming:
                return this.inputImageBasePath + 'Business_Cases_Mobile/success_page_business-sector_farming_01.jpg';
              case BusinessArea.industry:
                return this.inputImageBasePath + 'Business_Cases_Mobile/success_page_business-sector_industrie_01.jpg';
            }
            break;
          case 'powerConsumption':
            return this.inputImageBasePath + 'Consumption-Mobile/successpage_consumption_step' + (Math.floor((this.configuratorService.configurator.electricityConsumption / (20000 * 1000)) * 9) + 1) + '_01.jpg';
          case 'powerGeneration':
            if (this.configuratorService.configurator.PVSystemPower > 0) {
              return this.inputImageBasePath + 'Generation-Mobile/successpage_generation_step' + (Math.floor((this.configuratorService.configurator.PVSystemPower / 1000) * 10) + 1) + '_01.jpg';
            } else {
              return this.inputImageBasePath + 'Applications-Mobile/no_application_selection_mobile_01.jpg';
            }
          case 'mileage':
            return this.inputImageBasePath + 'Electric Cars-Mobile/successpage_electriccars_step' + this.configuratorService.configurator.electricCarChargingStations + '_01.jpg';
          case 'knowledgeLevel':
            return this.inputImageBasePath + 'Knowledge-Mobile/successpage_knowledgge_step' + this.configuratorService.configurator.knowledgeLevel + '_01.jpg';
          case 'application1':
            if (this.application1?.toLowerCase() == "peak load shaving") {
              return this.inputImageBasePath + 'Applications-Mobile/successpage_peak-shaving_preview_01.jpg';
            } else if (this.application1?.toLowerCase() == "self consumption optimization") {
              return this.inputImageBasePath + 'Applications-Mobile/successpage_self-con-opt_preview_01.jpg';
            } else if (this.application1?.toLowerCase() == "backup power") {
              return this.inputImageBasePath + 'Applications-Mobile/successpage_backup-power_preview_01.jpg';
            } else {
              return this.inputImageBasePath + 'Applications-Mobile/no_application_selection_mobile_01.jpg';
            }
          case 'application2':
            if (this.application2?.toLowerCase() == "peak load shaving") {
              return this.inputImageBasePath + 'Applications-Mobile/successpage_peak-shaving_preview_01.jpg';
            } else if (this.application2?.toLowerCase() == "self consumption optimization") {
              return this.inputImageBasePath + 'Applications-Mobile/successpage_self-con-opt_preview_01.jpg';
            } else if (this.application2?.toLowerCase() == "backup power") {
              return this.inputImageBasePath + 'Applications-Mobile/successpage_backup-power_preview_01.jpg';
            } else {
              return this.inputImageBasePath + 'Applications-Mobile/no_application_selection_mobile_01.jpg';
            }
          case 'application3':
            if (this.application3?.toLowerCase() == "peak load shaving") {
              return this.inputImageBasePath + 'Applications-Mobile/successpage_peak-shaving_preview_01.jpg';
            } else if (this.application3?.toLowerCase() == "self consumption optimization") {
              return this.inputImageBasePath + 'Applications-Mobile/successpage_self-con-opt_preview_01.jpg';
            } else if (this.application3?.toLowerCase() == "backup power") {
              return this.inputImageBasePath + 'Applications-Mobile/successpage_backup-power_preview_01.jpg';
            } else {
              return this.inputImageBasePath + 'Applications-Mobile/no_application_selection_mobile_01.jpg';
            }
        }
      }
    }
    else if (this.isTab) {
      if (this.toBePrinted) {
        if (this.application1?.toLowerCase() == "peak load shaving") {
          return this.inputImageBasePath + 'Applications-Tablet/successpage_peak-shaving_preview_01.jpg';
        } else if (this.application1?.toLowerCase() == "self consumption optimization") {
          return this.inputImageBasePath + 'Applications-Tablet/successpage_self-con-opt_preview_01.jpg';
        } else if (this.application1?.toLowerCase() == "backup power") {
          return this.inputImageBasePath + 'Applications-Tablet/successpage_backup-power_preview_01.jpg';
        }
      } else {
        switch (this.imageTag) {
          case 'businessArea':
            switch (this.configuratorService.configurator?.businessArea) {
              case BusinessArea.logistics:
                return this.inputImageBasePath + 'Business_Cases_Tablet/success_page_business-sector_bigbusiness_01.jpg';
              case BusinessArea.smallBusiness:
              case BusinessArea.somethingElse:
                return this.inputImageBasePath + 'Business_Cases_Tablet/success_page_business-sector_smalllbusines_01.jpg';
              case BusinessArea.farming:
                return this.inputImageBasePath + 'Business_Cases_Tablet/success_page_business-sector_farming_01.jpg';
              case BusinessArea.industry:
                return this.inputImageBasePath + 'Business_Cases_Tablet/success_page_business-sector_industrie_01.jpg';
            }
            break;
          case 'powerConsumption':
            return this.inputImageBasePath + 'Consumption-Tablet/successpage_consumption_step' + (Math.floor((this.configuratorService.configurator.electricityConsumption / (20000 * 1000)) * 9) + 1) + '_01.jpg';
          case 'powerGeneration':
            if (this.configuratorService.configurator.PVSystemPower > 0) {
              return this.inputImageBasePath + 'Generation-Tablet/successpage_generation_step' + (Math.floor((this.configuratorService.configurator.PVSystemPower / 1000) * 10) + 1) + '_01.jpg';
            }
            else {
              return this.inputImageBasePath + 'Applications-Tablet/no_application_selection_mobile_01.jpg';
            }
          case 'mileage':
            return this.inputImageBasePath + 'Electric Cars-Tablet/successpage_electriccars_step' + this.configuratorService.configurator.electricCarChargingStations + '_01.jpg';
          case 'knowledgeLevel':
            return this.inputImageBasePath + 'Knowledge-Tablet/successpage_knowledgge_step' + this.configuratorService.configurator.knowledgeLevel + '_01.jpg';
          case 'application1':
            if (this.application1?.toLowerCase() == "peak load shaving") {
              return this.inputImageBasePath + 'Applications-Mobile/successpage_peak-shaving_preview_01.jpg';
            } else if (this.application1?.toLowerCase() == "self consumption optimization") {
              return this.inputImageBasePath + 'Applications-Mobile/successpage_self-con-opt_preview_01.jpg';
            } else if (this.application1?.toLowerCase() == "backup power") {
              return this.inputImageBasePath + 'Applications-Mobile/successpage_backup-power_preview_01.jpg';
            } else {
              return this.inputImageBasePath + 'Applications-Tablet/no_application_selection_mobile_01.jpg';
            }
          case 'application2':
            if (this.application2?.toLowerCase() == "peak load shaving") {
              return this.inputImageBasePath + 'Applications-Tablet/successpage_peak-shaving_preview_01.jpg';
            } else if (this.application2?.toLowerCase() == "self consumption optimization") {
              return this.inputImageBasePath + 'Applications-Tablet/successpage_self-con-opt_preview_01.jpg';
            } else if (this.application2?.toLowerCase() == "backup power") {
              return this.inputImageBasePath + 'Applications-Tablet/successpage_backup-power_preview_01.jpg';
            } else {
              return this.inputImageBasePath + 'Applications-Tablet/no_application_selection_mobile_01.jpg';
            }
          case 'application3':
            if (this.application3?.toLowerCase() == "peak load shaving") {
              return this.inputImageBasePath + 'Applications-Tablet/successpage_peak-shaving_preview_01.jpg';
            } else if (this.application3?.toLowerCase() == "self consumption optimization") {
              return this.inputImageBasePath + 'Applications-Tablet/successpage_self-con-opt_preview_01.jpg';
            } else if (this.application3?.toLowerCase() == "backup power") {
              return this.inputImageBasePath + 'Applications-Tablet/successpage_backup-power_preview_01.jpg';
            } else {
              return this.inputImageBasePath + 'Applications-Tablet/no_application_selection_mobile_01.jpg';
            }
        }
      }
    }
    else {
      if (this.toBePrinted) {
        if (this.application1?.toLowerCase() == "peak load shaving") {
          return this.inputImageBasePath + 'Applications/successpage_peak-shaving_preview_01.jpg';
        } else if (this.application1?.toLowerCase() == "self consumption optimization") {
          return this.inputImageBasePath + 'Applications/successpage_self-con-opt_preview_01.jpg';
        } else if (this.application1?.toLowerCase() == "backup power") {
          return this.inputImageBasePath + 'Applications/successpage_backup-power_preview_01.jpg';
        }
      } else {
        switch (this.imageTag) {
          case 'businessArea':
            switch (this.configuratorService.configurator?.businessArea) {
              case BusinessArea.logistics:
                return this.inputImageBasePath + 'Business_Cases/success_page_business-sector_bigbusiness_01.jpg';
              case BusinessArea.smallBusiness:
              case BusinessArea.somethingElse:
                return this.inputImageBasePath + 'Business_Cases/success_page_business-sector_smalllbusines_01.jpg';
              case BusinessArea.farming:
                return this.inputImageBasePath + 'Business_Cases/success_page_business-sector_farming_01.jpg';
              case BusinessArea.industry:
                return this.inputImageBasePath + 'Business_Cases/success_page_business-sector_industrie_01.jpg';
            }
            break;
          case 'powerConsumption':
            return this.inputImageBasePath + 'Consumption/successpage_consumption_step' + (Math.floor((this.configuratorService.configurator.electricityConsumption / (20000 * 1000)) * 9) + 1) + '_01.jpg';
          case 'powerGeneration':
            if (this.configuratorService.configurator.PVSystemPower > 0) {
              return this.inputImageBasePath + 'Generation/successpage_generation_step' + (Math.floor((this.configuratorService.configurator.PVSystemPower / 1000) * 10) + 1) + '_01.jpg';
            } else {
              return this.inputImageBasePath + 'Applications/no_selection.jpg';
            }
          case 'mileage':
            return this.inputImageBasePath + 'Electric Cars/successpage_electriccars_step' + this.configuratorService.configurator.electricCarChargingStations + '_01.jpg';
          case 'knowledgeLevel':
            return this.inputImageBasePath + 'Knowledge/successpage_knowledgge_step' + this.configuratorService.configurator.knowledgeLevel + '_01.jpg';
          case 'application1':
            if (this.application1?.toLowerCase() == "peak load shaving") {
              return this.inputImageBasePath + 'Applications/successpage_peak-shaving_preview_01.jpg';
            } else if (this.application1?.toLowerCase() == "self consumption optimization") {
              return this.inputImageBasePath + 'Applications/successpage_self-con-opt_preview_01.jpg';
            } else if (this.application1?.toLowerCase() == "backup power") {
              return this.inputImageBasePath + 'Applications/successpage_backup-power_preview_01.jpg';
            } else {
              return this.inputImageBasePath + 'Applications/no_selection.jpg';
            }
          case 'application2':
            if (this.application2?.toLowerCase() == "peak load shaving") {
              return this.inputImageBasePath + 'Applications/successpage_peak-shaving_preview_01.jpg';
            } else if (this.application2?.toLowerCase() == "self consumption optimization") {
              return this.inputImageBasePath + 'Applications/successpage_self-con-opt_preview_01.jpg';
            } else if (this.application2?.toLowerCase() == "backup power") {
              return this.inputImageBasePath + 'Applications/successpage_backup-power_preview_01.jpg';
            } else {
              return this.inputImageBasePath + 'Applications/no_selection.jpg';
            }
          case 'application3':
            if (this.application3?.toLowerCase() == "peak load shaving") {
              return this.inputImageBasePath + 'Applications/successpage_peak-shaving_preview_01.jpg';
            } else if (this.application3?.toLowerCase() == "self consumption optimization") {
              return this.inputImageBasePath + 'Applications/successpage_self-con-opt_preview_01.jpg';
            } else if (this.application3?.toLowerCase() == "backup power") {
              return this.inputImageBasePath + 'Applications/successpage_backup-power_preview_01.jpg';
            } else {
              return this.inputImageBasePath + 'Applications/no_selection.jpg';
            }
        }
      }
    }
    return '';
  }

  setImageTag(val: string) {
    this.imageTag = val;
  }
}
