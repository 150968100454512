<div class="container position-relative container-desktop">
  <end-customer-portal-configurator-header [pageHeading]="'configurator.step1.heading'" [currentStep]="1"
    [routeToNextStep]="'/configurator/step2'"
    [isActive]="configurationService.configurator.step1UserInteracted"></end-customer-portal-configurator-header>
  <div class="stepss w-100">
    <div class="content-wrapper row  justify-content-between">
      <div class="left-content col-7 text-center">
        <div class="d-flex justify-content-center">
          <div class="top-section">
            <h1 class="title">{{'configurator.step1.title' | translate}}</h1>
            <p class="paragraph" style="margin-bottom: 0">{{'configurator.step1.para-1' | translate}}</p>

          </div>
        </div>
        <div class="industry-selection">
          <div class="business-type item"
            [ngClass]="{'item-active' : configurationService.configurator.businessArea == BusinessArea.smallBusiness}"
            (click)="setBusiness(BusinessArea.smallBusiness)">
            <span>
              <img class="smallBusiness" src="../../../assets/small business.svg">
            </span>
            <div class="business-type">{{'configurator.step1.smallBusiness' | translate}}</div>
          </div>
          <div class="button-industry item" (click)="setBusiness(BusinessArea.industry)"
            [ngClass]="{'item-active' : configurationService.configurator.businessArea == BusinessArea.industry}">
            <img src="../../../assets/industry.svg">
            <div class="business-type">{{'configurator.step1.industry' | translate}}</div>
          </div>
          <div class="button-big-business item"
            [ngClass]="{'item-active' : configurationService.configurator.businessArea == BusinessArea.logistics}"
            (click)="setBusiness(BusinessArea.logistics)">
            <img src="../../../assets/big-business.svg">
            <div class=" business-type">{{'configurator.step1.bigBusiness' | translate}}</div>
          </div>
          <div class="button-industry item"
            [ngClass]="{'item-active' : configurationService.configurator.businessArea == BusinessArea.farming}"
            (click)="setBusiness(BusinessArea.farming)">
            <img src="../../../assets/farming.svg">
            <div class="business-type">{{'configurator.step1.farming' | translate}}</div>
          </div>
        </div>
        <div class="foot-note d-flex align-items-center justify-content-center gap-2">
          <div class="custom-checkbox d-flex gap-1">
            <input type="checkbox" id="leftCheckbox" [(ngModel)]="someOtherBusinessAreaCheckbox"
              (ngModelChange)="checkIfSomethingElseIsSet()">
            <span class="checkmark"></span>
            <label class="text-start fs18" for="leftCheckbox">{{'configurator.step1.somethingElse' |
              translate}}</label>
          </div>

          <div class="text-area-wrapper">
            <textarea class="more-info" [maxLength]="200"
              placeholder="{{'configurator.step1.textAreaPlaceHolder' | translate}}"
              [readOnly]="configurationService.configurator.businessArea !== BusinessArea.somethingElse"
              [(ngModel)]='configurationService.configurator.businessAreaSomethingElse'></textarea><br>
            <label class="nopadding clrlbl" *ngIf="configurationService.configurator.businessAreaSomethingElse">
              {{configurationService.configurator.businessAreaSomethingElse.length}}/200</label>
            <br />
          </div>

        </div>
      </div>
      <div class="col-5 right-content position-relative">
        <div class="containers h-100">
          <div id="businessAreaCarousel" class="carousel slide h-100" data-bs-ride="carousel" data-bs-interval="60000">
            <div class="carousel-indicators">
              <ng-container *ngFor="let item of carouselData; let i = index">
                <ng-container *ngIf="item.selected">
                  <button *ngFor="let itemData of item.sliderContents; let j = index" type="button"
                    data-bs-target="#businessAreaCarousel" [attr.data-bs-slide-to]="j"
                    [ngClass]="{'active': j === 0}"></button>
                </ng-container>
              </ng-container>
            </div>
            <div class="carousel-inner h-100">
              <ng-container *ngFor="let item of carouselData; let i = index">
                <div *ngIf="item.selected" class="h-100">
                  <div *ngFor="let itemData of item.sliderContents; let j = index"
                    class="carousel-item position-relative h-100"
                    [ngClass]="{'active': configurationService.configurator.businessArea == undefined || (configurationService.configurator.businessArea == item.businessArea && j === 0)}">
                    <img src="{{itemData.image}}" class="carousel-img w-100">
                    <div class="caption-wrapper position-absolute" style="z-index: -1">
                      <div class="caption">
                        <p class="caption-title">{{item.title | translate}}</p>
                        <span class="caption-para">{{itemData.description | translate}}</span>
                      </div>
                      <div class="read-more d-flex" *ngIf="!isMobile">
                        <a data-bs-target="#businessAreaCarousel" data-bs-slide="next"> {{itemData.nextBtn |
                          translate}}</a>
                        <img src="assets/img/configurator/right-arrow.svg">
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <!--        <end-customer-action-btn [currentStep]="1" [routeToNextStep]="'/configurator/step2'"></end-customer-action-btn>-->
      </div>
    </div>
  </div>
  <end-customer-portal-configurator-footer [routeToNextStep]="'/configurator/step2'" [currentStep]="1"
    [isActive]="configurationService.configurator.step1UserInteracted"></end-customer-portal-configurator-footer>
</div>