import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Options } from "ngx-slider-v2/options";
import { ConfiguratorService } from "../utility/configurator.service";
import { KnowledgeLevelOption } from "../utility/knowledge-level-option.enum";
import { VerificationService } from "../../auth/verification/verification.service";

@Component({
  selector: 'end-customer-portal-configurator-step5',
  templateUrl: './step5.component.html',
  styleUrls: ['./step5.component.scss']
})
export class Step5Component implements OnChanges {
  @Input() visible!: boolean;
  videoSrc!: string;
  videoSrcFw: string = "assets/videos-rel1/lead_gen_animations_solo_new_x01/video/leadgen_knowledge_level_desktop_v3.mp4";
  // videoSrcBw: string = "assets/video/tv_con_knowledge_backward.mp4";
  videoSrcFwTablet: string = "assets/videos/tablet/lead_gen_knowledge_mt_01.mp4"

  knowledgeOption1 = false;
  knowledgeOption2 = false;
  knowledgeOption3 = false;
  isDesktop = false;
  isTablet = false;
  isMobile = false;
  videoSrcFwMobile: string = "assets/videos/lead_gen_knowledge_mp_01.mp4"
  constructor(public configuratorService: ConfiguratorService,
    private verificationService: VerificationService) {
    this.videoSrc = this.videoSrcFw;
    this.isMobile = this.verificationService.detectMob();
    this.isTablet = this.verificationService.detectTab();
    this.isDesktop = !this.isMobile && !this.isTablet;
  }
  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['visible'].currentValue == true) {
      const video = document.getElementById("video5");
      // this.oldValue = 3;
      setTimeout(() => {
        (video as any).currentTime = 0;
        (video as any).play();
        // setTimeout(() => {
        // (video as any).pause();
        // }, 2000 // Adjusted timeout based on frame count
        // )
      }, 300); // Adjusted timeout based on frame count
    }
  }
  // oldValue = this.configuratorService.configurator?.knowledgeLevel;
  KnowledgeLevelOption = KnowledgeLevelOption;
  options: Options = {
    floor: 1,
    ceil: 5,
    showSelectionBar: true,
    translate: (value: number): string => {
      if (value == 1 || value == 5) {
        return value.toString();
      } else {
        return '';
      }
    }
  };

  // bindVideo() {
  //   const video = document.getElementById("video5");
  //   const video1 = document.getElementById("video5a");
  //   const triggerIndex = this.configuratorService.configurator.knowledgeLevel;

  //   if (triggerIndex !== this.oldValue) {
  //     this.videoSrc = triggerIndex > this.oldValue ? this.videoSrcFw : this.videoSrcBw;
  //     let startTime;
  //     if (triggerIndex > this.oldValue) {
  //       startTime = this.configuratorService.configurator.knowledgeLevel - 2;
  //       this.oldValue = triggerIndex;
  //       (video as any).currentTime = startTime;
  //       (video as any).play();

  //       setTimeout(() => {
  //         (video as any).pause();
  //       }, 1000);
  //     } else {
  //       startTime = (video1 as any).duration - this.configuratorService.configurator.knowledgeLevel;
  //       this.oldValue = triggerIndex;
  //       (video1 as any).currentTime = startTime;
  //       (video1 as any).play();

  //       setTimeout(() => {
  //         (video1 as any).pause();
  //       }, 1000);
  //     }
  //   }
  // }

}
