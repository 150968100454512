<div class="next-step action-desktop position-absolute">
  <div class="row">
    <div class="col-6">
      <div class="row btn-wrapper">
        <div class="col-6" *ngIf="currentStep >= 2">
          <button (click)="configuratorService.goToPreviousStep(currentStep)"
            class="btn btn-light prevNextBtn  d-flex justify-content-center align-items-center gap-3">
            <img class="" src="/assets/img/left-arrow.svg" alt="">
            {{'prevBtnTitle' | translate}}</button>
        </div>
        <div class="col-6" *ngIf="currentStep < 2">
        </div>
        <div class="col-6">
          <button (click)="configuratorService.goToNextStep(currentStep, routeToNextStep )"
            class="btn btn-light prevNextBtn float-end d-flex justify-content-center align-items-center gap-4p"
            [ngClass]="{'active': isActive, 'bigBtn': currentStep == 6}">
            {{ (currentStep === 6 ? 'resultBtnTitle' : 'nextBtnTitle') | translate }}
            <img class="" src="/assets/img/right-arrow.svg" alt="">
          </button>

        </div>
      </div>
    </div>
    <div class="col-6"></div>
  </div>
</div>



<div class="next-step action-mobile position-absolute d-none">
  <div class="row row-wrapper">
    <div class="col-12 btn-wrapper-container">
      <div class="row btn-wrapper">
        <div class="col-6 p-0" *ngIf="currentStep >= 2">
          <button (click)="configuratorService.goToPreviousStep(currentStep)"
            class="btn btn-light prevNextBtn float-start">
            <img class=" me-1" src="/assets/img/left-arrow.svg" alt="">
            {{'prevBtnTitle' | translate}}</button>
        </div>
        <div class="col-6" *ngIf="currentStep <= 1"></div>

        <div class="col-6">
          <button (click)="configuratorService.goToNextStep(currentStep, routeToNextStep )"
            class="btn btn-light prevNextBtn float-end" [ngClass]="{'active': isActive, 'bigBtn': currentStep == 6}">
            {{ (currentStep === 6 ? 'resultBtnTitle' : 'nextBtnTitle') | translate }}
            <img class=" me-1" src="/assets/img/right-arrow.svg" alt="">
          </button>

        </div>
      </div>
    </div>

  </div>
</div>