import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "../auth/utility/user.model";
import { AppConstants } from "../core/constants";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    public loggedInUser!: User;
    userNameUpdated = new BehaviorSubject('');
    fetchUpatedUserName = this.userNameUpdated.asObservable();
    isLoggedIn = new BehaviorSubject(false);
    fetchIsLoggedInStatus = this.isLoggedIn.asObservable();
    loggedInUserToken!: string;
    firstTimeUser: boolean = false;

    constructor(private http: HttpClient) { }

    getUserInformation(email: string): Observable<User> {
        return this.http.post<User>(AppConstants.API_URL + 'User/user-info', { token: this.loggedInUserToken, email: email }
        )
    }

    createUserInDB(user: User) {
        return this.http.post<string>(AppConstants.API_URL + 'User/user', user);
    }

    userSignIn(user: User) {
        return this.http.post<any>(AppConstants.API_URL + 'User/sign-in', user);
    }
    isFirstLogin(email: string) {
        return this.http.get<{ hasAnsweredMarketingQuestions: boolean }>(AppConstants.API_URL + 'User/is-first-login?email=' + email);
    }
    isExistingUser(email: string) { // checks the AD
        return this.http.get<{ isExistingUser: boolean }>(AppConstants.API_URL + 'User/is-existing-user?email=' + email);
    }
    isExistingMarketingEmail(email: string) {
        return this.http.get<{ hasFilledMarketingForm: boolean }>(AppConstants.API_URL + 'User/has-filled-marketing-form?email=' + email);
    }
}
