import { Component } from '@angular/core';
import { UserService } from "../services/user.service";

@Component({
  selector: 'end-customer-portal-footer',
  templateUrl: './new-end-customer-portal-footer.component.html',
  styleUrls: ['./new-end-customer-portal-footer.component.scss']
})
export class EndCustomerPortalFooterComponent {
  isLoggedIn = false;
  constructor(private userService: UserService) {
    this.userService.fetchIsLoggedInStatus.subscribe(status => this.isLoggedIn = status);
  }
}
