<ng-container *ngIf="configuratorService.stepToShowInWindow != undefined">
    <div [hidden]="(configuratorService.stepToShowInWindow !=0)">
        <end-customer-portal-configurator-welcome></end-customer-portal-configurator-welcome>
    </div>
    <div [hidden]="(configuratorService.stepToShowInWindow !=1)">
        <end-customer-portal-configurator-step1
            [visible]="configuratorService.stepToShowInWindow ==1"></end-customer-portal-configurator-step1>
    </div>
    <div [hidden]="(configuratorService.stepToShowInWindow !=2)">
        <end-customer-portal-configurator-step2
            [visible]="configuratorService.stepToShowInWindow ==2"></end-customer-portal-configurator-step2>
    </div>
    <div [hidden]="(configuratorService.stepToShowInWindow !=3)">
        <end-customer-portal-configurator-step3
            [visible]="configuratorService.stepToShowInWindow ==3"></end-customer-portal-configurator-step3>
    </div>
    <div [hidden]="(configuratorService.stepToShowInWindow !=4)">
        <end-customer-portal-configurator-step4
            [visible]="configuratorService.stepToShowInWindow ==4"></end-customer-portal-configurator-step4>
    </div>
    <div [hidden]="(configuratorService.stepToShowInWindow !=5)">
        <end-customer-portal-configurator-step5
            [visible]="configuratorService.stepToShowInWindow ==5"></end-customer-portal-configurator-step5>
    </div>
    <div [hidden]="(configuratorService.stepToShowInWindow !=6)">
        <end-customer-portal-configurator-step6
            [visible]="configuratorService.stepToShowInWindow ==6"></end-customer-portal-configurator-step6>
    </div>
    <div [hidden]="(configuratorService.stepToShowInWindow !=7)">
        <end-customer-product-suggestion
            [visible]="configuratorService.stepToShowInWindow ==7"></end-customer-product-suggestion>
    </div>
</ng-container>