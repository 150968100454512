<div class="container configurator-body configurator-body-desktop w-100 p-0">
  <end-customer-portal-configurator-header [pageHeading]="'configurator.welcome.heading'"
    [pageHeading2]="'configurator.welcome.heading2'"></end-customer-portal-configurator-header>
  <div class="steps w-100">
    <div class="content-wrapper row  justify-content-between m-0">
      <div class="left-content col-7 text-center mt-5">
        <div class="d-flex justify-content-center align-items-center">
          <div class="top-section">
            <h1 class="welcome-subheading" [innerHTML]="'configurator.welcome.subheading' |translate"></h1>
            <p class="base-paragraph">{{'configurator.welcome.para1' | translate}} </p>
            <button class="configuration-btn btn btn-primary btn-primary " (click)="startConfiguration()">
              <span><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                  <path
                    d="M5 9C4.60218 9 4.22064 8.84196 3.93934 8.56066C3.65804 8.27936 3.5 7.89782 3.5 7.5C3.5 7.10218 3.65804 6.72064 3.93934 6.43934C4.22064 6.15804 4.60218 6 5 6M5 9C5.39782 9 5.77936 8.84196 6.06066 8.56066C6.34196 8.27936 6.5 7.89782 6.5 7.5C6.5 7.10218 6.34196 6.72064 6.06066 6.43934C5.77936 6.15804 5.39782 6 5 6M5 9V15M5 6V3M9.5 13.5C9.10218 13.5 8.72064 13.342 8.43934 13.0607C8.15804 12.7794 8 12.3978 8 12C8 11.6022 8.15804 11.2206 8.43934 10.9393C8.72064 10.658 9.10218 10.5 9.5 10.5M9.5 13.5C9.89782 13.5 10.2794 13.342 10.5607 13.0607C10.842 12.7794 11 12.3978 11 12C11 11.6022 10.842 11.2206 10.5607 10.9393C10.2794 10.658 9.89782 10.5 9.5 10.5M9.5 13.5V15M9.5 10.5V3M14 6.75C13.6022 6.75 13.2206 6.59196 12.9393 6.31066C12.658 6.02936 12.5 5.64782 12.5 5.25C12.5 4.85218 12.658 4.47064 12.9393 4.18934C13.2206 3.90804 13.6022 3.75 14 3.75M14 6.75C14.3978 6.75 14.7794 6.59196 15.0607 6.31066C15.342 6.02936 15.5 5.64782 15.5 5.25C15.5 4.85218 15.342 4.47064 15.0607 4.18934C14.7794 3.90804 14.3978 3.75 14 3.75M14 6.75V15M14 3.75V3"
                    stroke="#F0F0EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> </span> {{'configurator.welcome.button' | translate}}
            </button>
          </div>
        </div>

        <div class="foot-note row justify-content-center align-items-center">
          <p class="base-paragraph base-paragraph-2">{{'configurator.welcome.footnote.para1' | translate}}</p>
          <p class="base-paragraph base-paragraph-2"> {{'configurator.welcome.footnote.para2' |
            translate}}<a class="custom-anchor" target="_blank"
              href="https://www.tesvolt.com/de/partner.html">{{'configurator.welcome.footnote.para2Anchor' |
              translate}}</a></p>
        </div>
      </div>
      <div class="col-5 right-content m-0 p-0">
        <!-- <img class="w-100" src="assets/img/configurator/bg-tshv30e-50e-80e_v2_floor.png" alt=""> -->
        <div class="containers h-100">
          <video *ngIf="isDesktop" class="video-content w-100 video h-100" id="video0" autoplay playsInline>
            <source src="assets/videos-rel1/lead_gen_animations_solo_new_x01/video/leadgen_start_desktop_v3.mp4"
              type=" video/mp4">
          </video>

         <video *ngIf="isMobile" id="video0" class="video-content" autoplay playsInline>
           <source [src]="videoSrcFwMobile" type="video/mp4">
         </video>
          <video *ngIf="isTablet" id="video0" class="video-content" autoplay playsInline>
            <source [src]="videoSrcFwTablet" type="video/mp4">
          </video>
        </div>


      </div>


    </div>

  </div>
  <!-- <end-customer-portal-configurator-footer [isActive]='true'></end-customer-portal-configurator-footer> -->
</div>
